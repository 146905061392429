<template>
  <div>
    <b-row>
      <b-col lg="6">
        <ContactsWidget></ContactsWidget>
      </b-col>
      <b-col lg="6">
        <!-- <div class="row">
      <div class="col-md-12">
        <b-table striped hover :items="contacts"></b-table>
      </div>
    </div> -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ContactsWidget from "@/view/content/widgets/contacts/ContactsWidget.vue";

export default {
  data() {
    return {
      contacts: [],
      selectedContact: {},
      newUser: false,
    };
  },

  components: {
    ContactsWidget,
  },

  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
  },

  methods: {
    deleteContact(contact) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${this.formatContactName(contact)}?`
        )
        .then((value) => {
          if (value === true) {
            ApiService.delete(`contacts/${contact.id}`).then((response) => {
              this.getContacts();
            });
          } else {
            console.log("User Rejected");
          }
        })
        .catch((err) => {});
    },
    getContacts() {
      ApiService.query("contacts/my").then((response) => {
        this.contacts = response.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      });
    },
    editContact(contact) {
      ApiService.get(`contacts/${contact.id}`).then((res) => {
        this.selectedContact = res.data;
        this.newUser = false;

        this.$refs["my-modal"].show();
      });
    },
    addContact() {
      this.selectedContact = new Object({
        firstName: "",
        surname: "",
        mobileNumber: "",
      });

      this.newUser = true;
      this.$refs["my-modal"].show();
    },
    submitContact() {
      if (this.newUser) {
        ApiService.post(`contacts`, this.selectedContact).then((res) => {
          this.getContacts();
          this.$refs["my-modal"].hide();
        });
      } else {
        ApiService.put(`contacts`, this.selectedContact).then((res) => {
          this.getContacts();
          this.$refs["my-modal"].hide();
        });
      }
    },
    canSubmit() {
      if (
        this.selectedContact.firstName === "" ||
        this.selectedContact.surname === "" ||
        this.selectedContact.mobileNumber === ""
      ) {
        return true;
      } else {
        return false;
      }
    },
    formatContactName(contact) {
      return contact.firstName
        ? contact.firstName + " " + contact.surname
        : contact.surname;
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" },
    ]);

    this.selectedContact = new Object({
      firstName: "",
      surname: "",
      mobileNumber: "",
    });

    this.getContacts();
  },
};
</script>
