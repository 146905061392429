<template>
  <b-modal
    id="contact-modal"
    v-model="show"
    :hide-footer="true"
    :hide-header="true"
  >
    <div class="card card-custom">
      <div class="card-header">
        <h3 class="card-title">
          {{ newUser ? "Create Contact" : "Update Contact" }}
        </h3>

        <div class="symbol symbol-50 mr-1">
          <span class="symbol-label">
            <img
              :src="picture"
              class="h-50 align-self-center"
              style="margin-top: 10px"
              alt=""
            />
          </span>
        </div>
      </div>

      <!--form-->
      <div class="card-body">
        <div class="form-group mb-8"></div>
        <b-form-group>
          <label>First Name <span class="text-danger">*</span></label>
          <b-form-input
            class="mb-5"
            v-model="contact.firstName"
            @keypress="keyPressed"
            :state="isFirstNameValid"
          />

          <label>Surname <span class="text-danger">*</span></label>
          <b-form-input
            class="mb-5"
            v-model="contact.surname"
            @keypress="keyPressed"
            :state="isSurameValid"
          />

          <label>Mobile Number <span class="text-danger">*</span></label>
          <b-form-input
            id="mobile-number-input"
            v-model="contact.mobileNumber"
            @keypress="keyPressed"
            :formatter="formatter"
            :state="isMobileNumberValid"
          />
          <b-form-invalid-feedback id="mobile-number-input-feedback">
            Enter a valid Australian mobile number
          </b-form-invalid-feedback>
        </b-form-group>
      </div>
      <div
        class="card-footer"
        style="display: flex; justify-content: flex-end;"
      >
        <button type="cancel" class="btn btn-primary mr-2" @click="cancel">
          Cancel
        </button>
        <button
          type="submit"
          class="btn btn-primary mr-2"
          :disabled="!canSubmit()"
          @click="submitContact"
        >
          Submit
        </button>
      </div>
      <!-- </form> -->
    </div>
  </b-modal>
</template>
<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "contact-edit-modal",
  props: ["newUser", "contact"],
  data() {
    return {
      show: false,
    };
  },
  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },

    isMobileNumberValid() {
      var phoneno = /^04\d{8}$/;

      return !!this.contact.mobileNumber
        ? !this.contact.mobileNumber.replace(/\D/g, "").match(phoneno)
          ? false
          : null
        : false;
    },

    isFirstNameValid() {
      return !!this.contact.firstName
        ? !(this.contact.firstName.length > 0)
          ? false
          : null
        : false;
    },

    isSurameValid() {
      return !!this.contact.surname
        ? !(this.contact.surname.length > 0)
          ? false
          : null
        : false;
    },
  },
  methods: {
    cancel() {
      this.show = false;
    },

    keyPressed(e) {
      if (e.keyCode === 13) {
        if (this.canSubmit()) {
          this.submitContact();
        }
      }
    },

    canSubmit() {
      if (
        this.isFirstNameValid === false ||
        this.isSurameValid === false ||
        this.isMobileNumberValid === false
      ) {
        return false;
      } else {
        return true;
      }
    },

    submitContact() {
      if (this.newUser) {
        let newContact = { ...this.contact };
        newContact.mobileNumber = newContact.mobileNumber.replace(/\D/g, "");

        ApiService.post(`contacts`, newContact)
          .then((res) => {
            this.$emit("contactsUpdated");
            this.show = false;
          })
          .catch((error) => {
            console.log("Error saving list", error);
          });
      } else {
        let editedContact = { ...this.contact };
        editedContact.mobileNumber = editedContact.mobileNumber.replace(
          /\D/g,
          ""
        );

        ApiService.put(`contacts`, editedContact)
          .then((res) => {
            this.$emit("contactsUpdated");
            this.show = false;
          })
          .catch((error) => {
            console.log("Error saving list", error);
          });
      }
    },

    formatter(value) {
      let digits = value.replace(/\D/g, "");
      var result = digits;

      if (
        digits.length > 0 &&
        digits.charAt(0) !== "0" &&
        digits.charAt(0) !== "4" &&
        digits.charAt(1) !== "4"
      ) {
        result = "04" + digits.substring(0, digits.length);
      } else if (digits.length > 0 && digits.charAt(0) !== "0") {
        result = "0" + digits.substring(0, digits.length);
      }

      if (digits.length > 7) {
        result =
          digits.substring(0, 4) +
          " " +
          digits.substring(4, 7) +
          " " +
          digits.substring(7, 10);
      } else if (digits.length > 4) {
        result =
          digits.substring(0, 4) + " " + digits.substring(4, digits.length);
      }

      return result;
    },
  },
};
</script>
