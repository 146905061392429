<template>
  <div class="card card-custom gutter-b card-stretch card-shadowless bg-light">
    <ContactEditModal
      :contact="selectedContact"
      :newUser="newUser"
      @contactsUpdated="getContacts"
    ></ContactEditModal>
    <!--begin::Header-->
    <div class="card-header border-0 pt-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          My contacts
        </span>

        <span class="text-muted mt-3 font-weight-bold font-size-sm">
          These are your contacts. Others can see these but only you can edit
          them.
        </span>
      </h3>
      <div class="card-toolbar">
        <!-- <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4 active"
                data-toggle="tab"
                href="#kt_tab_pane_1_1"
              >
                Month
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_2"
              >
                Week
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link py-2 px-4"
                data-toggle="tab"
                href="#kt_tab_pane_1_3"
              >
                Day
              </a>
            </li>
          </ul> -->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-3 pb-0">
      <!--begin::Table-->
      <div class="table-responsive">
        <table class="table table-borderless table-vertical-center">
          <thead>
            <tr>
              <th class="p-0" style="width: 50px"></th>
              <th class="p-0" style="min-width: 100px"></th>
              <th class="p-0" style="min-width: 100px"></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="pl-0"></td>
              <td class="pl-0"></td>
              <td class="text-right pr-0">
                <a
                  href="#"
                  @click="addContact"
                  class="btn btn-icon btn-light btn-sm"
                >
                  <span class="svg-icon svg-icon-md svg-icon-primary">
                    <inline-svg
                      src="media/svg/icons/Communication/Add-contact.svg"
                    ></inline-svg>
                  </span>
                </a>
              </td>
            </tr>

            <template v-for="(item, i) in contacts">
              <tr v-bind:key="i">
                <td class="pl-0 py-4">
                  <div class="symbol symbol-50 symbol-light mr-1">
                    <span class="symbol-label">
                      <img
                        :src="picture"
                        class="h-50 align-self-center"
                        alt=""
                      />
                    </span>
                  </div>
                </td>
                <td class="pl-0">
                  <a
                    href="#"
                    class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >
                    {{ formatContactName(item) }}
                  </a>
                  <div>
                    <a class="text-muted font-weight-bold" href="#">
                      <!-- {{ item.mobileNumber }} -->
                      {{
                        item.mobileNumber.slice(0, 4) +
                          " " +
                          item.mobileNumber.slice(4, 7) +
                          " " +
                          item.mobileNumber.slice(7)
                      }}
                    </a>
                  </div>
                </td>
                <td class="text-right pr-0">
                  <!-- <a href="#" class="btn btn-icon btn-light btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-primary">
                            <inline-svg
                              src="media/svg/icons/General/Settings-1.svg"
                            ></inline-svg>
                          </span>
                        </a> -->

                  <a
                    href="#"
                    class="btn btn-icon btn-light btn-sm mx-3"
                    @click="editContact(item)"
                  >
                    <span class="svg-icon svg-icon-md svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Write.svg"
                      ></inline-svg>
                      <!--end::Svg Icon-->
                    </span>
                  </a>
                  <span
                    class="d-inline-block"
                    tabindex="0"
                    v-b-tooltip.hover.top
                    :title="
                      item.lists.length > 0
                        ? 'Contact will be removed from these lists: ' +
                          item.lists.map((i) => ' ' + i.name)
                        : null
                    "
                  >
                    <b-link
                      href="#"
                      class="btn btn-icon btn-light btn-sm"
                      @click="deleteContact(item)"
                    >
                      <span class="svg-icon svg-icon-md svg-icon-primary">
                        <!--begin::Svg Icon-->
                        <inline-svg
                          src="media/svg/icons/General/Trash.svg"
                        ></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </b-link>
                  </span>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
      <!--end::Table-->
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import ContactEditModal from "@/view/content/widgets/contacts/ContactEditModal.vue";

export default {
  name: "contacts-widget",
  components: {
    ContactEditModal,
  },
  data() {
    return {
      contacts: [],
      selectedContact: {},
      newUser: false,
    };
  },
  computed: {
    picture() {
      return (
        process.env.BASE_URL + "media/svg/avatars/avatar-single-orange.svg"
      );
    },
  },
  methods: {
    formatter(value) {
      let digits = value.replace(/\D/g, "");
      var result = digits;

      if (
        digits.length > 0 &&
        digits.charAt(0) !== "0" &&
        digits.charAt(0) !== "4" &&
        digits.charAt(1) !== "4"
      ) {
        result = "04" + digits.substring(0, digits.length);
      } else if (digits.length > 0 && digits.charAt(0) !== "0") {
        result = "0" + digits.substring(0, digits.length);
      }

      if (digits.length > 7) {
        result =
          digits.substring(0, 4) +
          " " +
          digits.substring(4, 7) +
          " " +
          digits.substring(7, 10);
      } else if (digits.length > 4) {
        result =
          digits.substring(0, 4) + " " + digits.substring(4, digits.length);
      }

      return result;
    },

    deleteContact(contact) {
      this.$bvModal
        .msgBoxConfirm(
          `Are you sure you want to delete ${this.formatContactName(contact)}?`,
          { autoFocusButton: "ok" }
        )
        .then((value) => {
          if (value === true) {
            ApiService.delete(`contacts/${contact.id}`).then((response) => {
              this.getContacts();
            });
          } else {
            console.log("User Rejected");
          }
        })
        .catch((err) => {});
    },

    getContacts() {
      ApiService.query("contacts/my").then((response) => {
        this.contacts = response.data.sort((a, b) =>
          a.name > b.name ? 1 : -1
        );
      });
    },

    editContact(contact) {
      ApiService.get(`contacts/${contact.id}`).then((res) => {
        this.selectedContact = { ...res.data };
        this.selectedContact.mobileNumber = this.formatter(
          this.selectedContact.mobileNumber
        );
        this.newUser = false;
        this.$bvModal.show("contact-modal");
      });
    },

    addContact() {
      this.newUser = true;
      this.selectedContact = {
        firstName: "",
        surname: "",
        mobileNumber: "",
      };
      this.$bvModal.show("contact-modal");
    },

    formatContactName(contact) {
      return contact.firstName
        ? contact.firstName + " " + contact.surname
        : contact.surname;
    },
  },

  mounted() {
    this.getContacts();
  },
};
</script>
